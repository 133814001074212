/** Available types of event flows in all application */
export enum WEBSITE_FLOW {
    BETA_REGISTRATION = 'BETA_REGISTRATION',
    BETA_APPROVAL = 'BETA_APPROVAL',
    OPEN_BETA = 'OPEN_BETA',
    ULC_REDEEM = 'ULC_REDEEM',
    CF_REDEEM = 'CF_REDEEM',
    PYC_REDEEM = 'PYC_REDEEM',
    TWITCH_PRIME = 'TWITCH_PRIME',
    STANDARD_REGISTRATION = 'STANDARD_REGISTRATION',
    FREE_WEEKEND = 'FREE_WEEKEND',
    TRIALS = 'TRIALS',
    DEMO = 'DEMO',
    FRIENDS_REFERRAL = 'FRIENDS_REFERRAL',
    GIVEAWAY = 'GIVEAWAY',
    PROMOTAB = 'PROMOTAB'
}
