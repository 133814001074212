import { COUNTRY, LOCATION_REGION } from '../localization/locations';
import { IPromoContextRegion, IPromoUser } from '../types/promo';

export enum WEBSITE_TYPE {
    INTERACTIVE = 'INTERACTIVE',
    PROMO = 'PROMO'
}

export enum PROMO_COOKIES_STATUS {
    ACCEPTED = 'accepted',
    REJECTED = 'rejected'
}

export enum PROMO_SECTION_TYPE {
    HEADER = 'HEADER',
    INTRO = 'INTRO',
    VIDEO = 'VIDEO',
    SALES = 'SALES',
    FOOTER = 'FOOTER',
    CAROUSEL = 'CAROUSEL',
    STEPS = 'STEPS',
}

export const IMAGE_KEY_PREFIX = 'carouselImage-';
export const SALES_IMAGE_KEY_PREFIX = 'offerImage-';
export const STEP_IMAGE_KEY_PREFIX = 'stepImage-';
export const AUDIENCE_CONTEXT_PREFIX = 'audience-';
export const OWNERSHIP_INHERITANCE_CLASS = 'ownership-to-inherit';

export enum CONTEXT_GROUP_TYPES {
    REGION = 'region',
    OWNERSHIP = 'ownership',
    COMMON = 'common'
}

export enum OWNERSHIP_TYPES {
    GAME_OWNERS = 'gameOwners',
    SEASON_OWNERS = 'seasonOwners',
    NOT_OWNER = 'notOwner',
}

export const CONTEXT_OWNERSHIP = [
    OWNERSHIP_TYPES.GAME_OWNERS,
    OWNERSHIP_TYPES.SEASON_OWNERS
];

export const VIDEO_URLS_COUNTRIES = [
    COUNTRY.CZ,
    COUNTRY.GB,
    COUNTRY.FR,
    COUNTRY.ES,
    COUNTRY.PT,
    COUNTRY.DE,
    COUNTRY.AT,
    COUNTRY.IT,
    COUNTRY.NL,
    COUNTRY.PL,
    COUNTRY.RU,
    COUNTRY.HU,
    COUNTRY.DK,
    COUNTRY.SE,
    COUNTRY.FI,
    COUNTRY.NO,
    COUNTRY.TR,
    COUNTRY.JP,
    COUNTRY.KR,
    COUNTRY.TW,
    COUNTRY.US,
    COUNTRY.CA,
    COUNTRY.MX,
    COUNTRY.BR,
    COUNTRY.SA,
    COUNTRY.AE,
    COUNTRY.AU,
    COUNTRY.NZ,
    COUNTRY.TH
];

export const CONTEXT_REGIONS: Array<IPromoContextRegion> = [
    { value: LOCATION_REGION.EMEA, label: 'EMEA' },
    { value: LOCATION_REGION.NCSA, label: 'NCSA' },
    { value: LOCATION_REGION.MENA, label: 'MENA' },
    { value: LOCATION_REGION.ANZ, label: 'ANZ' },
    { value: LOCATION_REGION.EE, label: 'Eastern Europe' },
    { value: LOCATION_REGION.CN, label: 'China' }
];

export enum CUSTOMIZE_TEXT_KEYS {
    TITLE = 'title',
    DESCRIPTION = 'description',
    BUTTON = 'button'
}

export const CUSTOMIZE_OWNERSHIP_SUBSTRING = 'Owners' as const;

export const MIN_STEPS_AMOUNT = 3;
export const MIN_SALES_AMOUNT = 1;

export const OWNERSHIPS_DROPDOWN_OPTIONS = [
    { value: OWNERSHIP_TYPES.GAME_OWNERS, text: 'Game' },
    { value: OWNERSHIP_TYPES.SEASON_OWNERS, text: 'Season Pass' },
    { value: OWNERSHIP_TYPES.NOT_OWNER, text: 'Not Owner' },
];

export const DEFAULT_USER: IPromoUser = {
    [CONTEXT_GROUP_TYPES.OWNERSHIP]: null,
    [CONTEXT_GROUP_TYPES.REGION]: null,
    countryCode: null
};

export const SECTION_NAME_REGEXP: RegExp = /^[a-zA-Z0-9\-]+$/;
