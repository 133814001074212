import { IWidgetConfigItem } from '../types';

/**
 * Available widget names
 * List be consistent with WEBSITE_FLOW enum, as well as "sitegen/core" project structure
 */
export enum WIDGET_NAME {
    DEMO = 'demo',
    FREE_WEEKEND = 'freeweekend',
    GIVEAWAY = 'giveaway',
    OPEN_BETA = 'open-beta',
    TRIALS = 'trials',
    ULC_REDEEM = 'ulc-redeem',
    CF_REDEEM = 'cf-redeem',
    PYC_REDEEM = 'pyc-redeem',
    TWITCH_PRIME = 'twitch-prime',
    FRIENDS_REFERRAL = 'friends-referral',
    BETA_REGISTRATION = 'beta-registration',
    BETA_APPROVAL = 'beta-approval',
    STANDARD_REGISTRATION = 'standard-registration',
    PROMOTAB = 'promotab'
}

export enum WIDGET_TYPE {
    FREE_CONTENT = 'free-content',
    REDEEM = 'redeem',
    CF_REDEEM = 'code-first-redeem',
    BETA_REGISTRATION = 'beta-registration',
    FRIENDS_REFERRAL = 'friends-referral'
}

export const WIDGET_CONFIG: { [key: string]: IWidgetConfigItem } = {
    [WIDGET_NAME.DEMO]: {
        widget: WIDGET_NAME.DEMO,
        widgetType: WIDGET_TYPE.FREE_CONTENT,
        widgetAnalytics: 'Demo',
        widgetFlowType: 'demo',
    },
    [WIDGET_NAME.FREE_WEEKEND]: {
        widget: WIDGET_NAME.FREE_WEEKEND,
        widgetType: WIDGET_TYPE.FREE_CONTENT,
        widgetAnalytics: 'Free Weekend',
        widgetFlowType: 'free weekend',
    },
    [WIDGET_NAME.GIVEAWAY]: {
        widget: WIDGET_NAME.GIVEAWAY,
        widgetType: WIDGET_TYPE.FREE_CONTENT,
        widgetAnalytics: 'Giveaway',
        widgetFlowType: 'giveaway',
    },
    [WIDGET_NAME.OPEN_BETA]: {
        widget: WIDGET_NAME.OPEN_BETA,
        widgetType: WIDGET_TYPE.FREE_CONTENT,
        widgetAnalytics: 'Open Beta',
        widgetFlowType: 'open beta',
    },
    [WIDGET_NAME.TRIALS]: {
        widget: WIDGET_NAME.TRIALS,
        widgetType: WIDGET_TYPE.FREE_CONTENT,
        widgetAnalytics: 'Free Trial',
        widgetFlowType: 'trial',
    },
    [WIDGET_NAME.ULC_REDEEM]: {
        widget: WIDGET_NAME.ULC_REDEEM,
        widgetType: WIDGET_TYPE.REDEEM,
        widgetAnalytics: 'Platform-first Redeem',
        widgetFlowType: 'ulc redeem',
    },
    [WIDGET_NAME.CF_REDEEM]: {
        widget: WIDGET_NAME.CF_REDEEM,
        widgetType: WIDGET_TYPE.REDEEM,
        widgetAnalytics: 'Code-first Redeem',
        widgetFlowType: 'ulc redeem',
    },
    [WIDGET_NAME.PYC_REDEEM]: {
        widget: WIDGET_NAME.PYC_REDEEM,
        widgetType: WIDGET_TYPE.REDEEM,
        widgetAnalytics: 'Pick-your-content Redeem',
        widgetFlowType: 'ulc redeem',
    },
    [WIDGET_NAME.BETA_REGISTRATION]: {
        widget: WIDGET_NAME.BETA_REGISTRATION,
        widgetType: WIDGET_TYPE.BETA_REGISTRATION,
        widgetAnalytics: 'Beta Registration',
        widgetFlowType: 'beta registration',
    },
    [WIDGET_NAME.BETA_APPROVAL]: {
        widget: WIDGET_NAME.BETA_APPROVAL,
        widgetType: WIDGET_TYPE.BETA_REGISTRATION,
        widgetAnalytics: 'Beta Approval',
        widgetFlowType: 'beta approval',
    },
    [WIDGET_NAME.FRIENDS_REFERRAL]: {
        widget: WIDGET_NAME.FRIENDS_REFERRAL,
        widgetType: WIDGET_TYPE.FRIENDS_REFERRAL,
        widgetAnalytics: 'Friends Referral',
        widgetFlowType: 'friends referral',
    }
};

export const getWidgetTypesToWidgetNames = (widgets: WIDGET_NAME[]): { [key: string]: WIDGET_TYPE } => {
    const result: { [key: string]: WIDGET_TYPE } = {};
    for (const widget of widgets) {
        result[widget] = WIDGET_CONFIG[widget].widgetType;
    }

    return result;
};
