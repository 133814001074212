import IDictionaries from '../localization/dictionaries';
import { LANGUAGE_REGION } from '../localization/language-regions';
import { ENV } from './environments';
import { WIDGET_NAME } from './widgets';
import { IContentPackItem } from '../types/content-packs';
import { PLAYER_SOURCE_TYPE } from './player-sources';
import { IRedeemCodeFormat } from '../types/redemption.types';
import { PLATFORM_TYPES } from './platforms';
import { IRewardTierItem } from '../types/rewards-tiers';
import { ICategory } from '../types/faq';
import { IAgeRatings } from './age-ratings';
import { IDataFormat } from '../classes/friends-referral-api';

export enum WEBSITE_MODE {
    EMBEDDED = 'embedded',
    STANDALONE = 'standalone'
}

interface IConfig {
    envName: ENV;
    applicationId: string;
    genomeId: string;
    twitchOfferID: string;
    urlPath: string;
    shouldInheritStyles: boolean;
    mode: WEBSITE_MODE;
    widgetName: WIDGET_NAME;
    maintenanceMode: boolean;
    platformSelection: Array<PLATFORM_TYPES>;
    isAnalyticsEnabled: boolean;
    analyticsFlowType?: string;
    analyticsBrand: string;
    analyticsGame: string;
    analyticsMDMBrandId: string;
    analyticsMDMInstallmentId: string;
    analyticsSiteName: string;
    contentPath: string;
    links: {
        faq: string
    };
    RSCodeFormat: IRedeemCodeFormat;
    eventCode: string;
    ageRatings: IAgeRatings;
    availableLanguages: { [key in LANGUAGE_REGION]: string[] };
    readonly selectedPackItemId: string;
    readonly contentPackItems?: IContentPackItem[];
    datesSettings?: IDatesSettings;
    styles: IStyles;
    websiteAbsolutePath: string;
    readonly betaRegistration?: IBetaRegistration;
    readonly freeContent?: {
        readonly useBrandedInstallerUrl: boolean,
        readonly brandedInstallerUrl: string
    };
    readonly rewardsTiers?: IRewardTierItem[];
    readonly friendsReferral?: {
        readonly showGameLogo: boolean,
        readonly campaignId: string,
        readonly rewardsImageSrc?: string,
        readonly inviteeRewardImageSrc?: string,
        readonly dataFormat: IDataFormat[],
        readonly faq: Array<ICategory>
    };
}

export interface IBetaRegistration {
    readonly code: string;
    readonly phaseId: number;
    readonly nda: boolean;
    readonly ndaContent?: string;
    readonly autoPush: {
        readonly enabled: boolean,
        readonly tag: string
    };
    readonly multiplePlatform: boolean;
    readonly restrictedPlayerSources: PLAYER_SOURCE_TYPE[];
    readonly isPlatformChangeAllowed: boolean;
    readonly friendInvite: {
        readonly enabled: boolean,
        readonly number: number
    };
    readonly excludedCountries?: string[];
}

export interface IDatesSettings {
    readonly startDate?: string;
    readonly endDate?: string;
    readonly shouldDisplayInUTC?: boolean;
}

export interface IStyles {
    areStylesAlreadyApplied: false;
    custom: string;
    default: string[];
    assets: string;
    /** Colors and styling is provided as CSS content rather then link */
    readonly colorsAndStylingContent?: string;
}

export interface IBootstrapConfig extends IConfig {
    dictionaries: IDictionaries;
}

export interface IEmbeddedAppConfig {
    readonly initialLanguage?: string;
}

export default IConfig;
