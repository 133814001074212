(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["2.18.3"] = factory();
	else
		root["SiteGen"] = root["SiteGen"] || {}, root["SiteGen"]["CoreLoader"] = root["SiteGen"]["CoreLoader"] || {}, root["SiteGen"]["CoreLoader"]["2.18.3"] = factory();
})(window, function() {
return 