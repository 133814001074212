import { IModule } from '@sitegen/common/types';
import { SITEGEN_BUILD } from '@sitegen/build/config/env/sitegen-build';
import { WIDGET_NAME, WIDGET_CONFIG, WIDGET_TYPE } from '@sitegen/common/constants';

/**
 * initialize reading of document.currentScript synchronously to avoid issue with async flow
 */
import './utils/current-script';

export const bootstrapLoader = (): Promise<IModule> => import(/* webpackChunkName: 'bootstrap' */ './bootstrap');

export const coreStateLoader = async (modules: string[]): Promise<object[]> => {
    const widgetTypes: WIDGET_TYPE[] = modules.map((widget: WIDGET_NAME): WIDGET_TYPE => WIDGET_CONFIG[widget].widgetType);
    return Promise.all(widgetTypes.map(async (widgetType: WIDGET_TYPE): Promise<object> => {
        return import(/* webpackChunkName: "[request]" */ `./widgets/${widgetType}/states/index`);
    }));
};

interface ICommonJSModule {
    readonly [key: string]: any; // eslint-disable-line
}

export const styleLoader = (): Promise<ICommonJSModule> => import(/* webpackChunkName: 'styles' */ './styles/shared-variables');

export const themeLoader = (widgetName: WIDGET_NAME) =>
    import(/* webpackChunkName: 'themes' */ `./widgets/${widgetName}/styles/theme-variables`);

export const version: string = SITEGEN_BUILD;

// need to disable typescript checking here,
// because @types/webpack & @types/webpack-env doesn't export module global interface
declare const module: any; // eslint-disable-line
if (module.hot) {
    module.hot.accept();
}
