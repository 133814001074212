import { IWebsiteSubdomain } from '../types';
import {
    formWebsiteSubdomain,
    FREE_WEEKEND_DOMAIN,
    FRIENDS_REFERRAL_DOMAIN,
    IWebsiteDomain,
    PC_PROMO,
    REDEEM_DOMAIN,
    REGISTER_DOMAIN,
    STANDARD_REGISTRATION_DOMAIN,
    TWITCH_PRIME_DOMAIN
} from './website.domains';
import { WEBSITE_FLOW } from './website-flow';
import { WIDGET_NAME } from './widgets';

export const WEBSITE_DEFAULT_ANALYTIC_NAME = 'site gen';

/**
 * flow id: the name of a directory where a website is deployed during publication
 */
export const WEBSITES_FLOW_SUFFIX: { [key: string]: IWebsiteDomain } = {
    [WEBSITE_FLOW.BETA_REGISTRATION]: REGISTER_DOMAIN,
    [WEBSITE_FLOW.BETA_APPROVAL]: REGISTER_DOMAIN,
    [WEBSITE_FLOW.OPEN_BETA]: REGISTER_DOMAIN,
    [WEBSITE_FLOW.ULC_REDEEM]: REDEEM_DOMAIN,
    [WEBSITE_FLOW.CF_REDEEM]: REDEEM_DOMAIN,
    [WEBSITE_FLOW.PYC_REDEEM]: REDEEM_DOMAIN,
    [WEBSITE_FLOW.TWITCH_PRIME]: TWITCH_PRIME_DOMAIN,
    [WEBSITE_FLOW.STANDARD_REGISTRATION]: STANDARD_REGISTRATION_DOMAIN,
    [WEBSITE_FLOW.FREE_WEEKEND]: FREE_WEEKEND_DOMAIN,
    [WEBSITE_FLOW.TRIALS]: REGISTER_DOMAIN,
    [WEBSITE_FLOW.DEMO]: REGISTER_DOMAIN,
    [WEBSITE_FLOW.FRIENDS_REFERRAL]: FRIENDS_REFERRAL_DOMAIN,
    [WEBSITE_FLOW.GIVEAWAY]: REGISTER_DOMAIN,
    [WEBSITE_FLOW.PROMOTAB]: PC_PROMO
};

/**
 * flow id: widget directory in the CORE package
 */
export const WEBSITES_WIDGET_NAME_BY_FLOW_ID: { [key: string]: WIDGET_NAME } = {
    [WEBSITE_FLOW.BETA_REGISTRATION]: WIDGET_NAME.BETA_REGISTRATION,
    [WEBSITE_FLOW.BETA_APPROVAL]: WIDGET_NAME.BETA_APPROVAL,
    [WEBSITE_FLOW.OPEN_BETA]: WIDGET_NAME.OPEN_BETA,
    [WEBSITE_FLOW.ULC_REDEEM]: WIDGET_NAME.ULC_REDEEM,
    [WEBSITE_FLOW.CF_REDEEM]: WIDGET_NAME.CF_REDEEM,
    [WEBSITE_FLOW.PYC_REDEEM]: WIDGET_NAME.PYC_REDEEM,
    [WEBSITE_FLOW.TWITCH_PRIME]: WIDGET_NAME.TWITCH_PRIME,
    [WEBSITE_FLOW.STANDARD_REGISTRATION]: WIDGET_NAME.STANDARD_REGISTRATION,
    [WEBSITE_FLOW.FREE_WEEKEND]: WIDGET_NAME.FREE_WEEKEND,
    [WEBSITE_FLOW.TRIALS]: WIDGET_NAME.TRIALS,
    [WEBSITE_FLOW.DEMO]: WIDGET_NAME.DEMO,
    [WEBSITE_FLOW.FRIENDS_REFERRAL]: WIDGET_NAME.FRIENDS_REFERRAL,
    [WEBSITE_FLOW.GIVEAWAY]: WIDGET_NAME.GIVEAWAY,
    [WEBSITE_FLOW.PROMOTAB]: WIDGET_NAME.PROMOTAB
};

export const WEBSITES_FLOW_ID_BY_WIDGET_NAME: { [key: string]: WEBSITE_FLOW } = {
    [WIDGET_NAME.BETA_REGISTRATION]: WEBSITE_FLOW.BETA_REGISTRATION,
    [WIDGET_NAME.BETA_APPROVAL]: WEBSITE_FLOW.BETA_APPROVAL,
    [WIDGET_NAME.OPEN_BETA]: WEBSITE_FLOW.OPEN_BETA,
    [WIDGET_NAME.ULC_REDEEM]: WEBSITE_FLOW.ULC_REDEEM,
    [WIDGET_NAME.CF_REDEEM]: WEBSITE_FLOW.CF_REDEEM,
    [WIDGET_NAME.PYC_REDEEM]: WEBSITE_FLOW.PYC_REDEEM,
    [WIDGET_NAME.TWITCH_PRIME]: WEBSITE_FLOW.TWITCH_PRIME,
    [WIDGET_NAME.STANDARD_REGISTRATION]: WEBSITE_FLOW.STANDARD_REGISTRATION,
    [WIDGET_NAME.FREE_WEEKEND]: WEBSITE_FLOW.FREE_WEEKEND,
    [WIDGET_NAME.TRIALS]: WEBSITE_FLOW.TRIALS,
    [WIDGET_NAME.DEMO]: WEBSITE_FLOW.DEMO,
    [WIDGET_NAME.FRIENDS_REFERRAL]: WEBSITE_FLOW.FRIENDS_REFERRAL,
    [WIDGET_NAME.GIVEAWAY]: WEBSITE_FLOW.GIVEAWAY,
    [WIDGET_NAME.PROMOTAB]: WEBSITE_FLOW.PROMOTAB
};

/**
 * flow id: website display name
 */
export const WEBSITE_DISPLAY_NAME_BY_FLOW_ID: { [key: string]: string } = {
    [WEBSITE_FLOW.BETA_REGISTRATION]: 'Beta registration',
    [WEBSITE_FLOW.BETA_APPROVAL]: 'Beta approval',
    [WEBSITE_FLOW.OPEN_BETA]: 'Open Beta',
    [WEBSITE_FLOW.ULC_REDEEM]: 'Platform-first Redeem',
    [WEBSITE_FLOW.CF_REDEEM]: 'Code-first Redeem',
    [WEBSITE_FLOW.PYC_REDEEM]: 'Pick-Your-Content Redeem',
    [WEBSITE_FLOW.TWITCH_PRIME]: 'Twitch Prime',
    [WEBSITE_FLOW.STANDARD_REGISTRATION]: 'Standard registration',
    [WEBSITE_FLOW.FREE_WEEKEND]: 'Free week-end',
    [WEBSITE_FLOW.TRIALS]: 'Free trial',
    [WEBSITE_FLOW.DEMO]: 'Demo',
    [WEBSITE_FLOW.FRIENDS_REFERRAL]: 'Friends Referral',
    [WEBSITE_FLOW.GIVEAWAY]: 'Giveaway',
    [WEBSITE_FLOW.PROMOTAB]: 'Promo Tab'
};

/**
 * widget name: website display name
 */
const WEBSITE_DISPLAY_NAME_BY_WIDGET_NAME: { [key: string]: string } = {};
Object.values(WEBSITE_FLOW).forEach((flowId: WEBSITE_FLOW) => {
    const widgetName: WIDGET_NAME = WEBSITES_WIDGET_NAME_BY_FLOW_ID[flowId];
    WEBSITE_DISPLAY_NAME_BY_WIDGET_NAME[widgetName] = WEBSITE_DISPLAY_NAME_BY_FLOW_ID[flowId];
});

/**
 * widget name: flow suffix
 */
const WEBSITE_FLOW_SUFFIX_BY_WIDGET_NAME: { [key: string]: string } = {};
Object.values(WEBSITE_FLOW).forEach((flowId: WEBSITE_FLOW) => {
    const widgetName: WIDGET_NAME = WEBSITES_WIDGET_NAME_BY_FLOW_ID[flowId];
    WEBSITE_FLOW_SUFFIX_BY_WIDGET_NAME[widgetName] = WEBSITES_FLOW_SUFFIX[flowId];
});

export { WEBSITE_DISPLAY_NAME_BY_WIDGET_NAME, WEBSITE_FLOW_SUFFIX_BY_WIDGET_NAME };

export const WEBSITE_FLOW_SUBDOMAIN: { [key: string]: IWebsiteSubdomain } = {
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.TWITCH_PRIME]]: formWebsiteSubdomain(TWITCH_PRIME_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.ULC_REDEEM]]: formWebsiteSubdomain(REDEEM_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.CF_REDEEM]]: formWebsiteSubdomain(REDEEM_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.PYC_REDEEM]]: formWebsiteSubdomain(REDEEM_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.FREE_WEEKEND]]: formWebsiteSubdomain(FREE_WEEKEND_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.GIVEAWAY]]: formWebsiteSubdomain(REGISTER_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.OPEN_BETA]]: formWebsiteSubdomain(REGISTER_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.TRIALS]]: formWebsiteSubdomain(REGISTER_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.DEMO]]: formWebsiteSubdomain(REGISTER_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.BETA_REGISTRATION]]: formWebsiteSubdomain(REGISTER_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.BETA_APPROVAL]]: formWebsiteSubdomain(REGISTER_DOMAIN),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.PROMOTAB]]: formWebsiteSubdomain(PC_PROMO),
    [WEBSITES_FLOW_SUFFIX[WEBSITE_FLOW.FRIENDS_REFERRAL]]: formWebsiteSubdomain(FRIENDS_REFERRAL_DOMAIN)
};
